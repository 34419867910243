<!--Horizontal Section-->
<div class="header-main zindex-19 relative">
  <!-- Mobile Header -->
  <div class="sticky">
    <div class="horizontal-header clearfix">
      <div class="container">
        <a id="horizontal-navtoggle" class="animated-arrow" (click)="mobileActive = !mobileActive"><span></span></a>
        <span class="smllogo"
          ><a routerLink="/"><img src="../../assets/images/brand/logo.png" alt="topsejur logo" /></a
        ></span>
        <!-- <a href="add-post.html" class="callusbtn"><i class="fe fe-log-in"></i></a> -->
      </div>
    </div>
  </div>
  <!-- /Mobile Header -->

  <div id="sticky-wrapper" class="sticky-wrapper absolute banners bg-light">
    <div class="horizontal-main clearfix py-lg-3 bg-white border-bottom">
      <div class="horizontal-mainwrapper container clearfix">
        <div class="desktoplogo">
          <a routerLink="/"><img src="../../assets/images/brand/logo.png" alt="" /></a>
        </div>
        <div class="desktoplogo-1">
          <a routerLink="/"><img src="../../assets/images/brand/logo.png" alt="" /></a>
        </div>
        <!--Nav-->
        <nav class="horizontalMenu clearfix d-md-flex" [class.active]="mobileActive">
          <ul class="horizontalMenu-list">
            <li>
              <a routerLink="/">Acasa</a>
            </li>
            <li>
              <a routerLink="/cauta/hoteluri">Hoteluri</a>
            </li>
            <li *ngIf="featureService.hasPackage">
              <a routerLink="/cauta/pachete">Vacante avion</a>
            </li>
            <li><a routerLink="/pagina/despre">Despre noi </a></li>
            <li><a routerLink="/pagina/contact">Contact </a></li>
            <li><a routerLink="/pagina/termeni">Termeni & Conditii</a></li>

            <li class="" *ngIf="!user">
              <span
                ><a class="btn btn-secondary" routerLink="/login"><i class="fe fe-log-in"></i> Login</a></span
              >
            </li>
            <li *ngIf="user" class="user">
              <a>Cont<span class="fa fa-user mr-1 ml-2 mb-1"></span><span class="fa fa-caret-down m-0"></span></a>
              <ul class="sub-menu">
                <li><a routerLink="/cont/comenzi">Rezervarile mele</a></li>
                <li><a routerLink="/cont/profil">Profil</a></li>
                <li><a routerLink="/cont/schimba-parola">Schimba parola</a></li>
                <li>
                  <a href="#" class="logout" (click)="logout()">Logout<i class="fe fe-log-out ml-1"></i></a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <!--Nav-->
      </div>
      <div class="body-progress-container">
        <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" id="myBar"></div>
      </div>
    </div>
  </div>
</div>
