import { User } from 'src/app/_models';
import { SubscriptionWebsocket } from 'src/app/_services/websocket.service';
import { FeatureModel } from '../models/feature.model';

export interface StoreState {
  features: FeatureModel;
  user: User;
  subscriptions: SubscriptionWebsocket[];
}

export enum StoreTypes {
  INIT = 'INIT',
  SET = 'SET',
  SET_USER = 'SET_USER',
  SET_FEATURE = 'SET_FEATURE',
  SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS',
  REMOVE = 'REMOVE',
  REMOVE_USER = 'REMOVE_USER',
  RESET = 'RESET',
  RESET_USER = 'RESET_USER',
  INIT_FEATURES = 'INIT_FEATURES'
}
