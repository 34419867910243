export class Location {
  name: string;
  nameEN?: string;
  type: number;
  uuid: string;
  dateCreated: Date;
  dateUpdated: Date;
  slug: string;
  position: number;
  fromTour: number;
  enabled: boolean;
  children: Array<Location>;
  pathIds: string;
  path: string;
  images: Array<LocationImage>;
  description: LocationDescription;
  id: number;
  parentId: number;
  parent: Location;

  constructor(location) {
    this.uuid = location?.uuid || '';
    this.name = location?.name  || '';
    this.nameEN = location?.nameEN  || null;
    this.type = location?.type  || 2;
    this.dateCreated = location?.dateCreated || null;
    this.dateUpdated = location?.dateUpdated || null;
    this.slug = location?.slug  || '';
    this.position = location?.position  || 0;
    this.fromTour = location?.fromTour || null;
    this.enabled = location?.enabled || true;
    this.children = location?.children || [];
    this.path = location?.path || '';
    this.pathIds = location?.pathIds || '';
    this.images = location?.images || [];
    this.description = location?.description ||  {
      lang: 'ro',
      description: '',
      title: '',
      turismTarget: '',
      turismAntropic: '',
      descriptionShort: '',
      entertaiment: '',
      dateCreated: null,
      dateUpdated: null
    };
    this.id = location?.id || 0;
    this.parentId = location?.parentId || 0;
    this.parent = location?.parent || null;
  }
}
export class LocationPaginationModel {
  items: Location[];
  meta: {
    currentPage: number,
    itemCount: number,
    itemsPerPage: number,
    totalItems: number,
    totalPages: number,
  };
}

export class LocationImage {
  image: string;
  thumb: string;
  size?: string;
  isMain?: boolean;
  title?: string;
  position?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  id?: string;
}

export class LocationDescription {
  title: string;
  lang: string;
  description: string;
  turismTarget?: string;
  turismAntropic?: string;
  entertaiment?: string;
  descriptionShort: string;
  uuid?: string;
  dateCreated?: Date;
  dateUpdated?: Date;
}
