declare global {
  interface Object {
    _has(path: string): boolean;

    _get(path: string, fallback: any): any;

    _truncate(name: string, minchars: number): string;
  }
}

Object.defineProperty(Object.prototype, '_has', {
  value(needle: any) {
    return !(this._get(needle) == null || typeof this._get(needle) === 'undefined');
  }
});

Object.defineProperty(Object.prototype, '_get', {
  value(needle: string, fallback: any) {
    let obj = this;
    const needles = needle.split('.');
    // tslint:disable-next-line:variable-name
    const needles_full = [];
    // tslint:disable-next-line:variable-name
    let needles_square;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < needles.length; i++) {
      needles_square = needles[i].split('[');
      if (needles_square.length > 1) {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < needles_square.length; j++) {
          if (needles_square[j].length) {
            needles_full.push(needles_square[j]);
          }
        }
      } else {
        needles_full.push(needles[i]);
      }
    }
    for (let i = 0; i < needles_full.length; i++) {
      // @ts-ignore
      const res = needles_full[i].match(/^((\d+)|"(.+)"|'(.+)')\]$/);
      if (res !== null) {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < res.length; j++) {
          if (res[j] !== undefined) {
            needles_full[i] = res[j];
          }
        }
      }

      if (obj[needles_full[i]] == null || typeof obj[needles_full[i]] === 'undefined') {
        return typeof fallback !== 'undefined' ? fallback : undefined;
      }
      obj = obj[needles_full[i]];
    }
    return obj;
  }
});

Object.defineProperty(Object.prototype, '_truncate', {
  value(minchars: number = 5) {
    return this.length > minchars ? `${this.substring(0, minchars)}...` : this;
  }
});

export class Utils {
  _getObjectByKey(obj, key) {
    if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
      return null;
    } else if (obj.hasOwnProperty(key)) {
      return obj[key];
    } else if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        const result = this._getObjectByKey(obj[i], key);
        if (result) {
          return result;
        }
      }
    } else {
      for (const k in obj) {
        const result = this._getObjectByKey(obj[k], key);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }
  getObjectByKey(obj, key, fallback: any = null, getSecondKey = '') {
    let found;
    try {
      found = this._getObjectByKey(obj, key);
    } catch (err) {
      found = null;
    }
    if (found) {
      if (getSecondKey) {
        return found._get(getSecondKey, fallback);
      }
      return found;
    }
    return fallback;
  }

  numbersOptions(count, defaultValue = 0) {
    if (!count) {
      return [];
    }
    // @ts-ignore
    return Array(count)
      .fill(1)
      .map((x, i) => i + 1);
  }
}
