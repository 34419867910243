import data from '@iconify/icons-ic/twotone-arrow-drop-down';
import { ImageItem } from 'ng-gallery';
import { BaseObjectModel } from './base.model';
import { Location } from './location.model';
import { SearchToFormDTO } from './search.model';
import { environment } from 'src/environments/environment';

export class Accommodation extends BaseObjectModel {
  name: string;
  nameEN?: string;
  type: number;
  uuid: string;
  slug: string;
  position: number;
  fromTour: number;
  enabled: boolean;
  children: Array<Accommodation>;
  pathIds: string;
  path: string;
  images: Array<AccommodationImage>;
  description: AccommodationDescription;
  id: number;
  parentId: number;
  parent: Accommodation;
  star: number;
  location: any;
  address: string;
  contact: string;
  email: string;
  latitude: string;
  longitude: string;
  imagesGallery: Array<ImageItem>;
  imageCover: string;
  private environment = environment;

  get mapLink() {
    let url = 'https://www.google.com/maps?q=';
    if (+this.latitude > 0 && +this.longitude > 0) {
      url += this.latitude + ',' + this.longitude;
    } else {
      url += this.name + ', ' + this.location.name;
    }
    return url;
  }

  constructor(accommodation) {
    super(accommodation, true);
    this.uuid = accommodation?.uuid || '';
    this.name = accommodation?.name || '';
    this.nameEN = accommodation?.nameEN || null;
    this.type = accommodation?.type || 2;
    this.dateCreated = accommodation?.dateCreated || null;
    this.dateUpdated = accommodation?.dateUpdated || null;
    this.slug = accommodation?.slug || '';
    this.position = accommodation?.position || 0;
    this.fromTour = accommodation?.fromTour || null;
    this.enabled = accommodation?.enabled || true;
    this.children = accommodation?.children || [];
    this.path = accommodation?.path || '';
    this.pathIds = accommodation?.pathIds || '';
    this.images = accommodation?.images || [];
    this.description = accommodation?.description || {
      lang: 'ro',
      description: '',
      title: '',
      turismTarget: '',
      turismAntropic: '',
      descriptionShort: '',
      entertaiment: '',
      dateCreated: null,
      dateUpdated: null
    };
    this.id = accommodation?.id || 0;
    this.parentId = accommodation?.parentId || 0;
    this.parent = accommodation?.parent || null;
    this.star = accommodation?.star || 0;
    this.location = accommodation?.location || null;
    this.imagesGallery = this.images.map((image) => {
      return new ImageItem({
        src: environment.apiUploadUrl + '/images' + image.image,
        thumb: environment.apiUploadUrl + '/images' + image.image
      });
    });
    this.imageCover = this.environment.apiUploadUrl + '/images' + this.getImageCover();
  }
  getImageCover() {
    let image = '';
    if (this.images) {
      const imageFirst = this.images[0]?.image || '';
      const imageMain = this.images.find((i) => i.isMain)?.image;
      image = imageMain || imageFirst;
    }
    return image;
  }
}

export class AccommodationPaginationModel {
  items: Accommodation[];
  meta: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export class AccommodationImage {
  image: string;
  thumb: string;
  size?: string;
  isMain?: boolean;
  title?: string;
  position?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  id?: string;
}

export class AccommodationDescription {
  title: string;
  lang: string;
  description: string;
  descriptionShort: string;
  roomOption?: string;
  services?: string;
  tarif?: string;
  localisation: string;
  titlePage: string;
  descPage: string;
  unitManualType: string;
  uuid?: string;
}

export class AccommodationPrice {
  id: number;
  price: number;
  taxes: number;
  availability: string;
  unique: string;
  value: string;
  name: string;
  room: string;
  roomTour: string;
  meal: string;
  costDetails: string;
  quoteType: string;
  costType: string;
  costDescription: string;
  childrenAgeMin: number;
  childrenAgeMax: number;
  locationId: number;
  accommodationId: number;
  roomId: number;
  roomTourId: number;
  accommodationTourId: number;
  countryId: number;
  tourId: number;
  eb: string;
  ebExpiry: Date;
  extraDays: number;
  extraDaysExpiry: Date;
  nonRefundable: boolean;
  searchId: number;
  dateCreated: Date;
  dateUpdated: Date;
  dateDeleted: Date;
  dateCheckIn: string;
  dateCheckOut: string;
  uuid: string;
  accommodation?: Accommodation;
  location?: Location;
  search?: SearchToFormDTO;
  priceFull: number;
  priceFullRon: number;
  services: AccommodationPriceService[];
  detailsFlight: AccommodationPriceService[];
  constructor(data: any) {
    for (const [key, value] of Object.entries(data)) {
      this[key] = value;
    }
    if (this.accommodation) {
      this.accommodation = new Accommodation(this.accommodation);
    }
    if (this.location) {
      this.location = new Location(this.location);
    }
    if (this.search) {
      this.search = new SearchToFormDTO(this.search);
    }
    if (this.services?.length) {
      this.detailsFlight = this.services.filter((df) => /transfer|avion/is.test(df.name));
      console.log(this.detailsFlight);
    }
  }
}

export class AccommodationSearch extends BaseObjectModel {
  accommodationId: number;
  accommodation: Accommodation;
  finished: boolean;
  dateCompleted: Date;
  offers: number;
  priceMin: number;
  priceMax: number;
  dateStarted: Date;
  meals: string[];
  rooms: string[];
  searchId: number;
  order: number;
  hasOffers: boolean;
  accommodationPrices: Array<AccommodationPrice> = [];
  resultsVisible: boolean;
  isVisible: boolean;
  isVisiblePagination: boolean;

  constructor(data: any) {
    super(data, true);
    this.accommodation = new Accommodation(data.accommodation);
    this.isVisible = true;
    this.accommodationPrices = data.accommodationPrices?.map((ap) => new AccommodationPrice(ap)) || [];
    this.isVisiblePagination = true;
  }
}

export class AccommodationSearchObject {
  constructor(data: any) {
    for (const [key, value] of Object.entries(data)) {
      this[key] = value;
    }
  }
}

export class AccommodationPriceService {
  code: number;
  name: string;
  type: number;
  gross: number;
  price: number;
  checkIn: string;
  checkOut: string;
  transport: {
    type: string;
    seats: number;
    arrival: string;
    company: string;
    departure: string;
    arrivalCode: string;
    departureCode: string;
    transportNumber: string;
  };
  commission: 0;
  availabilityCode: string;
  // tslint:disable-next-line:no-shadowed-variable
  constructor(data: any) {
    for (const [key, value] of Object.entries(data)) {
      this[key] = value;
    }
  }
}
