import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FeatureService } from 'src/@hop/services/feature.service';
import { User } from 'src/app/_models';
import { AuthenticationService } from 'src/app/_services';

@Component({
  selector: 'hop-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  mobileActive = false;
  user: User = null;
  constructor(private router: Router, private authentification: AuthenticationService, public featureService: FeatureService) {}

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.mobileActive = false;
    });
    this.authentification.user$.subscribe((user) => {
      this.user = user;
    });
  }

  logout() {
    this.authentification.logout();
  }
}
