import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { FingerprintService } from '../@hop/services/fingerprint.service';
import { FeatureService } from 'src/@hop/services/feature.service';
import * as cookie from 'js-cookie';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'topsejur-fronend';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private fingerprintService: FingerprintService,
    private featureService: FeatureService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe((data) => {
        this.titleService.setTitle(data.title);
      });
    });

    // this.featureService.setCookie();
    this.route.queryParams.subscribe((params) => {
      this.featureService.setCookie(params);
    });
    if (cookie.get('returnUrl')) {
      const returnUrl = cookie.get('returnUrl');
      this.router.navigate([returnUrl]);
      cookie.remove('returnUrl');
    }
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
