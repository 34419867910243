// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.

import { features } from 'src/features/features.prod';

// The list of file replacements can be found in `angular.json`.
const mainUrl = 'https://www.topsejur.ro';
const sentryUrl = 'https://81aeaaa8a7394ca7b01be31f934e9c2a@o1427981.ingest.sentry.io/4503907011067904';
const isActivatedSentry = true;

export const environment = {
  production: false,
  apiUrl: mainUrl + '/api',
  apiUploadUrl: mainUrl + '/api',
  apiSocketUrl: mainUrl,
  mainUrl,
  sentryUrl,
  isActivatedSentry,
  // enabledSentry,
  features
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
