import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../../@hop/layout/layout.module';
import { CustomLayoutComponent } from './custom-layout.component';
import { FooterModule } from '../../@hop/layout/footer/footer.module';
import { NavigationModule } from '../../@hop/layout/navigation/navigation.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [CustomLayoutComponent],
  imports: [
    CommonModule,
    LayoutModule,
    FooterModule,
    NavigationModule,
    RouterModule
  ]
})
export class CustomLayoutModule {
}
