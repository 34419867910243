import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Utils } from './services/utils';
import { LayoutModule } from './layout/layout.module';
import { FingerprintService } from './services/fingerprint.service';
import { FormUtilsService } from './services/form-utils-service';

@NgModule({
  imports: [CommonModule, LayoutModule],
  exports: [LayoutModule],
  providers: [Utils, FingerprintService, FormUtilsService]
})
export class HopModule {}
