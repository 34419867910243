import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class InterceptGetInterceptor implements HttpInterceptor {
  constructor() {}

  current = new Date();
  timestamp = this.current.getTime();

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newRequest =
      request.method === 'GET'
        ? request.clone({
            params: request.params.append('timestamp', this.timestamp.toString())
          })
        : request;
    return next.handle(newRequest);
  }
}
