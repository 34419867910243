<ngb-toast
    *ngFor="let toast of toastService.toasts"
    [class]="toast.classname"
    [autohide]="true"
    [delay]="toast.delay || 5000"
    (hidden)="toastService.remove(toast)"
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"><a (click)="toastService.remove(toast)">X</a></ng-template>
  </ng-template>

  <ng-template #text>{{ toast.textOrTpl }} <a (click)="toastService.remove(toast)">X</a></ng-template>
</ngb-toast>
