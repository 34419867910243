import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { AuthGuard } from './_helpers';
import { AccommodationsListModule } from './pages/apps/accommodations-list/accommodations-list.module';
const title = 'TopSejur';
const routes: Routes = [
  {
    path: 'register',
    loadChildren: () => import('./pages/pages/auth/register/register.module').then((m) => m.RegisterModule),
    data: {
      title: `${title} Register`
    }
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
    data: {
      title: `${title} Forgot Password`
    }
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then((m) => m.ComingSoonModule),
    data: {
      title: `${title} In curand`
    }
  },
  {
    path: '',
    component: CustomLayoutComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/apps/home/home.module').then((m) => m.HomeModule),
        data: {
          title: `${title} Home`
        }
      },
      {
        path: 'login',
        loadChildren: () => import('./pages/pages/auth/login/login.module').then((m) => m.LoginModule),
        data: {
          title: `${title} Login`
        }
      },

      {
        path: 'schimbare-parola',
        loadChildren: () => import('./pages/pages/auth/password-change/password-change.module').then((m) => m.PasswordChangeModule),
        data: {
          title: `${title} Login`
        }
      },
      {
        path: 'cauta',
        children: [
          {
            path: 'hoteluri',
            loadChildren: () => import('./pages/apps/accommodations-list/accommodations-list.module').then((m) => m.AccommodationsListModule)
          },
          {
            path: 'pachete',
            loadChildren: () =>
              import('./pages/apps/accommodations-packages/accommodations-packages.module').then((m) => m.AccommodationsPackagesModule)
          }
        ]
      },
      {
        path: 'pagina',
        children: [
          {
            path: '**',
            loadChildren: () => import('./pages/apps/pages-static/pages-static.module').then((m) => m.PagesStaticModule)
          }
        ]
      },

      {
        path: 'hotel',
        children: [
          {
            path: '**',
            loadChildren: () => import('./pages/apps/accommodation/accommodation.module').then((m) => m.AccommodationModule)
          }
        ]
      },
      {
        path: 'rezerva',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'hotel/:id',
            loadChildren: () => import('./pages/apps/reservation/reservation.module').then((m) => m.ReservationModule)
          }
        ]
      },
      {
        path: 'cont',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'comenzi',
            loadChildren: () => import('./pages/account/bookings/bookings.module').then((m) => m.BookingsModule)
          },
          {
            path: 'profil',
            loadChildren: () => import('./pages/apps/profile/profile.module').then((m) => m.ProfileModule)
          },
          {
            path: 'schimba-parola',
            loadChildren: () => import('./pages/pages/auth/password-change-user/password-change-user.module').then((m) => m.PasswordChangeUserModule)
          }
        ]
      },
      {
        path: 'apps',
        children: [
          {
            path: 'editor',
            loadChildren: () => import('./pages/apps/editor/editor.module').then((m) => m.EditorModule)
          }
        ]
      },
      {
        path: '**',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then((m) => m.Error404Module),
        data: {
          title: `${title} Pagina negasita`
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'corrected',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
