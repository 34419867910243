<!--Footer Section-->
<section>
  <footer class="bg-dark-purple text-white">
    <div class="py-4 footer-main text-white-50 p-0 border-bottom-0">
      <div class="container">
        <div class="row">
          <div class="col-lg">
            <div class="footer-links float-xl-left">
              <a routerLink="/pagina/cum-functioneaza" class="btn btn-link">Cum functioneaza</a>
              <a routerLink="/pagina/privacy" class="btn btn-link">Politica de confidentialitate</a>
              <a routerLink="/pagina/termeni" class="btn btn-link">Termeni & Conditii</a>
              <a routerLink="/pagina/contact" class="btn btn-link">Contact</a>
            </div>
          </div>
          <!--<div class="col-lg col-lg-auto">
            <div class="form-group d-flex mb-0 mt-2 mt-lg-0">
              <div class="mr-4 w100">
                <select class="form-control select2" data-placeholder="Select Country">
                  <option value="en">English</option>
                  <option value="en">Arabic</option>
                  <option value="en">German</option>
                  <option value="en">Greek</option>
                </select>
              </div>
              <div class="mr-4 w100">
                <select class="form-control select2" data-placeholder="Select Country">
                  <option value="en">USD</option>
                  <option value="en">EUR</option>
                  <option value="en">INR</option>
                  <option value="en">GBP</option>
                </select>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>
    <div class="footer-main">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-12">
            <!-- <h6 class="mb-2">Social</h6> -->
            <ul class="social-icons mb-0 mt-3">
              <li>
                <a class="social-icon" href="https://www.facebook.com/topsejur/"><i class="fa fa-facebook text-white-50"></i></a>
              </li>
            </ul>
            <h6 class="mb-2 mt-5">Plati</h6>
            <ul class="payments mb-0">
              <!-- <li>
                <a href="#" class="payments-icon"><i class="fa fa-cc-amex" aria-hidden="true"></i></a>
              </li> -->
              <li>
                <a href="#" class="payments-icon"><i class="fa fa-cc-visa" aria-hidden="true"></i></a>
              </li>
              <li>
                <a href="#" class="payments-icon"><i class="fa fa-credit-card-alt" aria-hidden="true"></i></a>
              </li>
              <li>
                <a href="#" class="payments-icon"><i class="fa fa-cc-mastercard" aria-hidden="true"></i></a>
              </li>
              <!-- <li>
                <a href="#" class="payments-icon"><i class="fa fa-cc-paypal" aria-hidden="true"></i></a>
              </li> -->
            </ul>
          </div>
          <!-- <div class="col-xl-2 col-lg-4 col-md-12">
            <h6>Contul meu</h6>
            <ul class="list-unstyled mb-0">
              <li>
                <a href="#"><i class="fa fa-angle-double-right mr-2 text-secondary"></i> My Dashboard</a>
              </li>
              <li>
                <a href="#"><i class="fa fa-angle-double-right mr-2 text-secondary"></i> Favorites</a>
              </li>
              <li>
                <a href="#"><i class="fa fa-angle-double-right mr-2 text-secondary"></i> Listings</a>
              </li>
              <li>
                <a href="#"><i class="fa fa-angle-double-right mr-2 text-secondary"></i> Managed</a>
              </li>
            </ul>
          </div> -->
          <div class="col-xl-2 col-lg-4 col-md-12">
            <h6>Servicii</h6>
            <ul class="list-unstyled mb-0">
              <li>
                <a routerLink="/pagina/contractul-cu-turistul"><i class="fa fa-angle-double-right mr-2 text-secondary"></i> Contractul cu turistul</a>
              </li>
              <li>
                <a routerLink="/pagina/licenta-de-turism"><i class="fa fa-angle-double-right mr-2 text-secondary"></i> Licenta de turism</a>
              </li>
              <li>
                <a routerLink="/pagina/modalitati-de-plata"><i class="fa fa-angle-double-right mr-2 text-secondary"></i>Modalitati de plata</a>
              </li>
              <li>
                <a routerLink="/pagina/polita-de-asigurare"><i class="fa fa-angle-double-right mr-2 text-secondary"></i>Polita de asigurare</a>
              </li>
            </ul>
          </div>
          <div class="col-xl-2 col-lg-4 col-md-12">
            <h6 class="mt-6 mt-xl-0">Linkuri utile</h6>
            <ul class="list-unstyled mb-0">
              <li>
                <a href="https://anpc.ro/"><i class="fa fa-angle-double-right mr-2 text-secondary"></i> ANPC | tel: 0800 080 999</a>
              </li>
              <li>
                <a href="http://turism.gov.ro/"><i class="fa fa-angle-double-right mr-2 text-secondary"></i> Autoritatea Nationala pentru Turism</a>
              </li>
              <li>
                <a href="https://www.politiadefrontiera.ro/"><i class="fa fa-angle-double-right mr-2 text-secondary"></i> Politia de Frontiera</a>
              </li>
            </ul>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-12">
            <h6 class="mt-6 mt-xl-0">Contact Info</h6>
            <ul class="list-unstyled mb-0">
              <li>
                <a href="#"><i class="fa fa-home mr-3 text-secondary"></i> Str. Constantin Cristescu, Nr. 2 110057 Pitești</a>
              </li>
              <li>
                <a href="#"><i class="fa fa-envelope mr-3 fs-12 text-secondary"></i> office@topsejur.ro</a>
              </li>
              <li>
                <a href="#"><i class="fa fa-phone mr-3 text-secondary"></i> +40 735 333221 </a>
              </li>
              <li>
                <a href="#"><i class="fa fa-phone mr-3 text-secondary"></i> +40 741 522522 </a>
              </li>
              <li>
                <a href="#"><i class="fa fa-phone mr-3 text-secondary"></i> +40 248 212120 </a>
              </li>
              <li>
                <a href="#"><i class="fa fa-phone mr-3 text-secondary"></i> +40 723 303010 </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row responsive-font-example ml-1 mr-1"><h4>Acceptam tichete de vacanta pentru sejururi in Romania</h4></div>
      <div class="row d-flex">
        <div class="ml-3 mt-3 mt-xl-0">
          <!-- <div style="width: 170px" class="ml-3 mt-3 mt-xl-0"> -->
          <img class="img-responsive" src="../../../../../assets/images/tichete/tichet1.jpeg" />
        </div>
        <div class="ml-3 mt-3 mt-xl-0">
          <!-- <div style="width: 170px" class="ml-3 mt-3 mt-xl-0"> -->
          <img class="img-responsive" src="../../../../../assets/images/tichete/tichet2.jpeg" />
        </div>
        <div class="ml-3 mt-3 mt-xl-0">
          <!-- <div style="width: 170px" class="ml-3 mt-3 mt-xl-0"> -->
          <img class="img-responsive" src="../../../../../assets/images/tichete/tichet3.jpeg" />
        </div>
      </div>
    </div>
    <div class="bg-dark-purple text-white-50 p-0">
      <div class="container">
        <div class="row d-flex">
          <div class="col-lg-12 col-sm-12 mt-3 mb-3 text-center">
            © 2021 <a href="https://www.topsejur.ro" class="text-secondary">TopSejur</a>. Made with ❤️ &nbsp; by
            <a href="https://hoptraining.ro" class="text-secondary">Hoptraining</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</section>
<!--Footer Section-->
