import { Injectable } from '@angular/core';
import { FeatureService } from './feature.service';

@Injectable({
  providedIn: 'root'
})
export class LogServiceService {
  constructor(private featureSerivce: FeatureService) {}

  log(content) {
    if (this.featureSerivce.isDebug === true) {
      console.log(content);
    }
  }
}
