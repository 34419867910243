import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HopDebugToolComponent } from './hop-debug-tool.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [HopDebugToolComponent],
  imports: [CommonModule, NgbModule],
  exports: [HopDebugToolComponent]
})
export class HopDebugToolModule {}
