import { Location } from './location.model';
import { Accommodation, AccommodationSearch } from './accommodation.model';
import moment from 'moment';
import { BaseObjectModel } from './base.model';

class SearchDTO {
  locationOrAccommodation: Location | Accommodation;
  accommodation: Accommodation;
  dateCheckIn: string | {};
  nights: number;
  adults: number;
  children: number;
  children1Age: number;
  children2Age: number;
  children3Age: number;
  children4Age: number;
  children5Age: number;

  constructor(value: any) {
    this.nights = value ? +value.nights : 0;
    this.adults = value ? +value.adults : 0;
    this.children = value ? +value.children : 0;
    this.children1Age = this.children ? +value.children1Age : 0;
    this.children2Age = this.children ? +value.children2Age : 0;
    this.children3Age = this.children ? +value.children3Age : 0;
    this.children4Age = this.children ? +value.children4Age : 0;
    this.children5Age = this.children ? +value.children5Age : 0;
    this.locationOrAccommodation = value ? value.locationOrAccommodation : null;
    this.accommodation = value ? (value.accommodation ? value.accommodation : { id: 0, name: '' }) : { id: 0, name: '' };
  }
}

class SearchDTOPackages extends SearchDTO {
  isPackage: boolean;

  constructor(value: any) {
    super(value);
    this.isPackage = true;
  }
}
export class SearchToApiDTOPackages extends SearchDTOPackages {
  locationId: number;
  locationFromId: number;

  constructor(value: any) {
    super(value);
    this.locationId = value.locationTo ? value.locationTo.id : 0;
    this.locationFromId = value.locationFrom ? value.locationFrom.id : 0;
    // this.accommodationId = value.accommodation?.id || null;
    this.dateCheckIn = value.dateCheckIn;
    /*if (value.dateCheckIn && value.dateCheckOut) {
      this.nights = -moment(value.dateCheckIn, 'YYYY-MM-DD')
        .diff(moment(value.dateCheckOut, 'YYYY-MM-DD'), 'days');
      this.dateCheckIn = {day: +moment(value.dateCheckIn, 'YYYY-MM-DD').format('D'), month: +moment(value.dateCheckIn, 'YYYY-MM-DD').
      format('M'), year: moment(value.dateCheckIn, 'YYYY-MM-DD').year()};
    }*/
    this.dateCheckIn = moment(this.dateCheckIn, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }
}

export class SearchToApiDTO extends SearchDTO {
  locationId: number;
  accommodationId: number;

  constructor(value: any) {
    super(value);
    if (value.locationOrAccommodation.isAccommodation) {
      this.locationId = value.locationOrAccommodation.location.id ? value.locationOrAccommodation.location.id : 0;
      this.accommodationId = value.locationOrAccommodation.id ? value.locationOrAccommodation.id : 0;
    } else {
      this.locationId = value.locationOrAccommodation ? value.locationOrAccommodation.id : 0;
      this.accommodationId = value.accommodation?.id || null;
    }

    if (value.dateCheckIn && this.nights) {
      this.dateCheckIn = moment({
        month: +value.dateCheckIn.month - 1,
        day: value.dateCheckIn.day,
        year: value.dateCheckIn.year
      }).format('YYYY-MM-DD');
    }
    /*if (value.dateCheckIn && value.dateCheckOut) {
      this.nights = -moment(value.dateCheckIn, 'YYYY-MM-DD')
        .diff(moment(value.dateCheckOut, 'YYYY-MM-DD'), 'days');
      this.dateCheckIn = {day: +moment(value.dateCheckIn, 'YYYY-MM-DD').format('D'), month: +moment(value.dateCheckIn, 'YYYY-MM-DD').
      format('M'), year: moment(value.dateCheckIn, 'YYYY-MM-DD').year()};
    }*/
  }
}

export class SearchToFormDTO extends SearchDTO {
  constructor(value: any) {
    super(value);

    if (value.accommodation?.id) {
      let object = value.accommodation;
      object.location = value.location;
      object.isAccommodation = true;
      value.location = object;
      this.locationOrAccommodation = object;
    } else {
      this.locationOrAccommodation = value.location;
    }
    this.accommodation = null;

    this.dateCheckIn = value
      ? {
          day: +moment(value?.dateCheckIn, 'YYYY-MM-DD').format('D'),
          month: +moment(value?.dateCheckIn, 'YYYY-MM-DD').format('M'),
          year: moment(value?.dateCheckIn, 'YYYY-MM-DD').year()
        }
      : {};
  }
}

export class SearchToFormDTOPackage extends SearchDTO {
  locationTo: Location;
  locationFrom: Location;
  constructor(value: any) {
    super(value);
    delete this.locationOrAccommodation;
    delete this.accommodation;
    this.locationTo = value?.location;
    this.locationFrom = value?.locationFrom;
    this.dateCheckIn = value?.dateCheckIn;
  }
}

export class SearchResultsObject extends BaseObjectModel {
  accommodationSearches: Array<AccommodationSearch>;
  progressPercent: number;
  accommodationId: any;

  constructor(value: any) {
    super(value, true);
    this.accommodationSearches = value.accommodationSearches.map((accommodationSearch) => new AccommodationSearch(accommodationSearch));
  }
}
