export class FeatureModel {
  hasPackage = true;
  isDebug = false;
  isGoogleAuth = true;
  constructor(featureInput: any) {
    for (const [key, value] of Object.entries(featureInput)) {
      if (this[key] !== typeof undefined) {
        this[key] = value;
      }
    }
  }
}
