import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Accommodation, AccommodationPaginationModel } from '../models/accommodation.model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

@Injectable({ providedIn: 'root' })
export class FingerprintService {
  endpoint = `${environment.apiUrl}/user-fingerprints`;
  private fp: any;
  private fingerprint = '';
  private fingerprintData: any;
  private now = Date.now();

  public getBrowserFingerprint() {
    return this.fingerprint;
  }

  async getAppId() {
    return new Promise((resolve, reject) => {
      if (this.fingerprint) {
        resolve(this.fingerprint + '-' + this.now);
      } else {
        const interval = setInterval(() => {
          if (this.fingerprint) {
            clearInterval(interval);
            resolve(this.fingerprint + '-' + this.now);
          }
        }, 50);
      }
    });
  }

  constructor(private http: HttpClient) {
    this.saveFingerprint().then();
  }

  getDetailsById(id) {
    return this.http.get<Accommodation>(this.endpoint + '/' + id);
  }

  async getFingerprint() {
    this.fp = await FingerprintJS.load();
    // Get the visitor identifier when you need it.
    const result = await this.fp.get();

    // This is the visitor identifier:
    const visitorId = result.visitorId;
    return result;
  }

  async saveFingerprint() {
    this.fingerprintData = await this.getFingerprint();
    this.fingerprint = this.fingerprintData.visitorId;
    // await this.saveFingerprintToDB();
  }

  private async saveFingerprintToDB() {
    return this.http
      .post<boolean>(this.endpoint, {
        fingerprint: this.getBrowserFingerprint(),
        data: this.fingerprintData,
        agent: navigator.userAgent,
        appId: this.getAppId()
      })
      .toPromise();
  }
}
