<div class="stickyDebug" *ngIf="featureService.isDebug">
  <!-- <a *ngIf="activate" href="#" class="btn btn-pill btn-info mr-2"><i class="fa fa-key" aria-hidden="true"></i></a> -->

  <div class="debugTool" *ngIf="activate">
    <!-- <button class="btn btn-danger">dfdfdfdfdf</button> -->
    <div ngbDropdown>
      <button type="button" class="btn btn-primary" id="dropdownConfig" ngbDropdownToggle>Features</button>
      <div class="dropupBox" ngbDropdownMenu aria-labelledby="dropdownConfig">
        <div class="dropupBox__column-text">
          <label *ngFor="let value of featureService.features$ | async | keyvalue" class="custom-switch custom-switch--space">
            <span class="custom-switch-description">{{ value.key }}</span>
            <input
              (click)="featureService.setValue(value.key, !value.value)"
              [checked]="value.value"
              type="checkbox"
              name="custom-switch-checkbox"
              class="custom-switch-input"
            />
            <span class="custom-switch-indicator"></span>
          </label>
        </div>
      </div>
    </div>
    <div ngbDropdown>
      <button type="button" class="btn btn-primary" id="dropdownConfig" ngbDropdownToggle>Test</button>
      <div class="dropupBox" ngbDropdownMenu aria-labelledby="dropdownConfig">
        <!-- <label *ngFor="let value of featureService.features | keyvalue" class="custom-switch custom-switch--column">
          <span class="custom-switch-description">{{ value.key }}</span>
          <input
            (click)="setCookie(value)"
            [checked]="getValueCookie(value.key)"
            type="checkbox"
            name="custom-switch-checkbox"
            class="custom-switch-input"
          />
          <span class="custom-switch-indicator"></span>
        </label> -->
      </div>
    </div>
  </div>

  <div>
    <a (click)="onClick()" class="btn btn-pill btn-secondary"><i class="fa fa-key" aria-hidden="true"></i></a>
  </div>
</div>
