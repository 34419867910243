import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as cookie from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { User } from '../_models';
import { environment } from '../../environments/environment';
import moment from 'moment';
import { ObservableStore } from '@codewithdan/observable-store';
import { StoreState, StoreTypes } from 'src/@hop/services/store-state';
import { FeatureService } from 'src/@hop/services/feature.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends ObservableStore<StoreState> {
  constructor(private router: Router, private http: HttpClient, private featureService: FeatureService) {
    super({
      trackStateHistory: true,
      logStateChanges: featureService.isDebug === true ? true : false
    });

    const initialStates = {
      user: null
    };
    this.setState(initialStates, StoreTypes.INIT);
    this.stateChanged.subscribe((state) => {
      this.user$.next(state?.user);
    });

    const auth: AuthenticationPayload = JSON.parse(cookie.get('auth') || '{}');
    if (auth.user) {
      this.setState({ user: auth.user }, StoreTypes.SET_USER);
      this.jwtToken$.next(auth.jwt.token);
      this.jwtRefreshToken$.next(auth.jwt.refreshToken);
      this.startRefreshTokenTimer();
    }
  }

  public get user(): User {
    const user: User = this.getStateProperty('user');
    return user;
  }

  user$: BehaviorSubject<User> = new BehaviorSubject(null);
  jwtToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private jwtRefreshToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public get jwtToken(): string {
    return this.jwtToken$.value;
  }

  // helper methods

  private refreshTokenTimeout;

  login(username: string, password: string): Observable<AuthenticationPayload> {
    return this.http
      .post<any>(
        `${environment.apiUrl}/login`,
        { username, password } // { withCredentials: true }
      )
      .pipe(
        map((data) => {
          this.setState({ user: data.user }, StoreTypes.SET_USER);
          this.jwtToken$.next(data.jwt.token);
          this.jwtRefreshToken$.next(data.jwt.refreshToken);
          cookie.set('auth', data, { expires: 183000 });
          this.startRefreshTokenTimer();
          return data;
        })
      );
  }

  logout() {
    if (!this.jwtRefreshToken$.value) {
      return of(true);
    }
    this.http
      .post<any>(
        `${environment.apiUrl}/logout`,
        { refreshToken: this.jwtRefreshToken$.value }
        // { withCredentials: true }
      )
      .subscribe();
    this.stopRefreshTokenTimer();
    this.setState({ user: null }, StoreTypes.RESET_USER);
    this.jwtToken$.next(null);
    this.jwtRefreshToken$.next(null);
    cookie.remove('auth');

    // this.router.navigate(['/login']);
    location.reload();
  }

  refreshToken(): Observable<AuthenticationPayload> {
    if (!this.jwtRefreshToken$.value || this.jwtToken$.value) {
      return of(null);
    }
    const url = `${environment.apiUrl}/refresh-token`;
    return this.http
      .post<any>(
        url,
        { refreshToken: this.jwtRefreshToken$.value } // { withCredentials: true }
      )
      .pipe(
        map(
          (data) => {
            this.setState({ user: data.user }, StoreTypes.SET_USER);
            this.jwtToken$.next(data.jwt.token);
            data.jwt.refreshToken = this.jwtRefreshToken$.value;
            cookie.set('auth', data, { expires: 183000 });
            this.startRefreshTokenTimer();
            return data;
          },
          catchError((err) => {
            return throwError(err);
          })
        )
      );
  }

  private startRefreshTokenTimer() {
    const actualTimeStamp = moment().unix();
    const expire: any = jwt_decode(this.jwtToken);
    const expireTimeStamp = expire.exp;
    const milisecondsRemaing = (expireTimeStamp - actualTimeStamp) * 1000;
    const timeoutToRefreshToken = milisecondsRemaing - 60 * 5 || 0;
    if (milisecondsRemaing < 0) {
      this.jwtToken$.next(null);
    }
    // parse json object from base64 encoded jwt token
    // const jwtToken = JSON.parse(atob(this.jwtToken$.value.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    // const expires = new Date(jwtToken.exp * 1000);
    // const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeoutToRefreshToken);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  sendPassword(email: string): Observable<boolean> {
    return this.http.post<any>(`${environment.apiUrl}/recover-password`, { email }).pipe(
      map((data) => {
        return !!data;
      })
    );
  }

  passwordChange(password: any, token: any): Observable<boolean> {
    return this.http.post<any>(`${environment.apiUrl}/change-password`, { password, token }).pipe(
      map((data) => {
        return data;
      })
    );
  }
  passwordChangeUser(currentPassword: any, newPassword: any): Observable<boolean> {
    return this.http.post<any>(`${environment.apiUrl}/change-password-user`, { currentPassword, newPassword }).pipe(
      map((data) => {
        return data;
      })
    );
  }
}

export interface AuthenticationPayload {
  user: User;
  isAdmin?: boolean;
  jwt: {
    token: string;
    refreshToken?: string;
  };
}
