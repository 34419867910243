import moment from 'moment';

export class BaseObjectModel {
  id: number;
  uuid: string;
  dateCreated?: moment.Moment;
  dateUpdated?: moment.Moment;
  dateDeleted?: moment.Moment;
  constructor(data: any, autoFill = false) {
    this.id = data?.id ? data.id : 0;
    this.uuid = data?.uuid ? data.uuid : null;
    this.dateCreated = data?.dateCreated ? moment(data.dateCreated) : null;
    this.dateUpdated = data?.dateUpdated ? moment(data.dateUpdated) : null;
    this.dateDeleted = data?.dateDeleted ? moment(data.dateDeleted) : null;
    if (autoFill) {
      this.autoFillProperties(data);
    }
  }

  autoFillProperties(data: any, ignoreDefined = true) {
    if (data !== undefined && data !== null) {
      for (const [key, value] of Object?.entries(data)) {
        if (!(ignoreDefined && typeof this[key] !== 'undefined')) {
          this[key] = value;
        }
      }
    }
  }
}
