import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { FeatureService } from 'src/@hop/services/feature.service';
import { FormUtilsService } from 'src/@hop/services/form-utils-service';
import { LogServiceService } from 'src/@hop/services/log-service.service';
import * as cookie from 'js-cookie';
import { StoreState } from 'src/@hop/services/store-state';
import { FeatureModel } from 'src/@hop/models/feature.model';

@Component({
  selector: 'app-hop-debug-tool',
  templateUrl: './hop-debug-tool.component.html',
  styleUrls: ['./hop-debug-tool.component.scss']
})
export class HopDebugToolComponent implements OnInit {
  activate: boolean = false;
  constructor(public featureService: FeatureService, private logService: LogServiceService) {}

  ngOnInit(): void {}

  onClick() {
    this.activate = this.activate === false ? true : false;
  }
}
