<!--Loader-->
<!--<div id="global-loader" class="bg-primary">
  <div class="loader-svg-img">
    <img src="../../assets/images/brand/2.png" class="" alt="">
    <div class="flight-icon"><i class="fa fa-plane"></i></div>
  </div>
</div>-->
<!--/Loader-->

<hop-navigation></hop-navigation>
<main class="content">
  <router-outlet></router-outlet>
</main>
<hop-footer></hop-footer>

