import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HopModule } from '../@hop/hop.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { AuthenticationService } from './_services';
import { appInitializer, ErrorInterceptor, JwtInterceptor } from './_helpers';
import { ToastModule } from '../@hop/components/toast/toast.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { InterceptGetInterceptor } from './Interceptors/intercept-get.interceptor';
import { HopDebugToolModule } from 'src/@hop/components/hop-debug-tool/hop-debug-tool.module';
import { default as data } from '../../package.json';
const version = data.version;
const config: SocketIoConfig = { url: environment.apiSocketUrl, options: {} };

import * as Sentry from '@sentry/angular';

import { Router } from '@angular/router';

import { BrowserTracing } from '@sentry/tracing';

if (environment.isActivatedSentry) {
  Sentry.init({
    dsn: environment.sentryUrl,
    // enabled: environment.enabledSentry,
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', `${environment.apiUrl}`],
        routingInstrumentation: Sentry.routingInstrumentation
      })
    ],
    release: version,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Hop
    HopModule,
    CustomLayoutModule,
    ToastModule,
    SocketIoModule.forRoot(config),
    NgbAlertModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    GalleryModule,
    LightboxModule,
    HopDebugToolModule,
    NgbModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthenticationService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptGetInterceptor, multi: true },

    Title,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
