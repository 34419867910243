import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Utils } from './utils';

@Injectable({
  providedIn: 'root'
})
export class FormUtilsService {
  private ignore: string[];

  constructor(private fb: UntypedFormBuilder, private utils: Utils) {}

  createFormFromObject(item: any, ignore: string[] = ['parent']) {
    this.ignore = ignore;
    const form: UntypedFormGroup | UntypedFormArray | UntypedFormControl = this.processObject(item);
    return form;
  }
  processObject(item: any, name = ''): UntypedFormGroup | UntypedFormArray | UntypedFormControl {
    if (this.ignore.includes(name)) {
      return item;
    }
    if (this.isObject(item)) {
      const object = Object.keys(item).reduce((prev, curr) => {
        prev[curr] = this.processObject(item[curr], curr);
        return prev;
      }, {});
      return this.fb.group(object);
    } else if (this.isArray(item)) {
      return this.fb.array(
        item.map((itemElement) => {
          return this.processObject(itemElement);
        })
      );
    } else {
      return item;
    }
  }
  private isArray(item: any): boolean {
    return Array.isArray(item);
  }
  private isObject(item: any): boolean {
    return typeof item === 'object' && item !== null && !this.isArray(item);
  }

  // simulate radio button
  selectOnlyOne(previousItems: Array<any>, changedItems: any[], compareField: string, checkField: string): Array<any> {
    if (changedItems.length !== previousItems.length) {
      previousItems = changedItems;
      return changedItems;
    }
    let differentImage = null;
    changedItems.forEach((imageChanged) => {
      const previous = previousItems.find((previousImage) => previousImage[compareField] === imageChanged[compareField]);
      if (previous) {
        if (imageChanged[checkField] !== previous[checkField]) {
          differentImage = imageChanged;
        }
      }
    });
    changedItems.map((imageChanged) => {
      imageChanged[checkField] = imageChanged[compareField] === (differentImage ? differentImage[compareField] : null);
    });
    return changedItems;
  }

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  markFormGroupTouched(formGroup: any) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  setValidators(form: any, validators: Array<any>) {
    validators.forEach((validator) => {
      let obj: UntypedFormControl = this.utils.getObjectByKey(form, validator.name, null);
      obj.setValidators(validator.validators);
    });
  }
}
